<template>
  <header class="topbar">
    <b-navbar
      toggleable="lg"
      :type="`${navbarColor == 'white' ? 'light' : 'dark'}`"
      class="p-0"
      :variant="navbarColor"
    >
      <div class="container-fluid">
        <b-navbar-nav
          ><b-nav-item @click="showMobileSidebar" class="d-block d-lg-none"
            ><i class="mdi mdi-menu fs-3"></i></b-nav-item
        ></b-navbar-nav>
        <div class="mx-2">
          <LogoDark v-if="navbarColor == 'white'" />
          <LogoLight v-else />
        </div>
        <b-navbar-toggle target="nav-collapse" class="mx-2"
          ><i class="ti-more"></i
        ></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav class="">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <!-- -----------------------------------------------------------
            Language Dropdown
          ----------------------------------------------------------- -->
            <b-nav-item-dropdown right no-caret>
              <template #button-content>
                <i :class="getCurrentLanguage.lang"></i>
              </template>
              <b-dropdown-item @click="changeLanguage('en')"
                ><i class="mr-2 flag-icon flag-icon-us"></i>
                English</b-dropdown-item
              >
              <b-dropdown-item @click="changeLanguage('br')"
                ><i class="mr-2 flag-icon flag-icon-fr"></i>
                Português</b-dropdown-item
              >
            </b-nav-item-dropdown>

            <!-- -----------------------------------------------------------
            Profile Dropdown
          ----------------------------------------------------------- -->
            <b-nav-item-dropdown right no-caret class="mailbox">
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <img
                  src="../../../assets/images/users/1.jpg"
                  alt="user"
                  class="rounded-circle"
                  width="31"
                />
              </template>
              <b-dropdown-text class="bg-primary text-white">
                <div class="d-flex align-items-center py-2">
                  <b-img
                    src="@/assets/images/users/1.jpg"
                    width="50"
                    rounded="circle"
                  />
                  <div class="ml-3">
                    <h4 class="mb-0">Steave Jobs</h4>
                    <span class="font-weight-light">varun@gmail.com</span>
                  </div>
                </div>
              </b-dropdown-text>
              <b-dropdown-item href="#" class="d-flex align-items-center">
                <feather type="user" class="feather-sm mr-2 mb-n1"></feather>
                Profile
              </b-dropdown-item>
              <b-dropdown-item href="#" class="d-flex align-items-center">
                <feather type="mail" class="feather-sm mr-2 mb-n1"></feather>
                Inbox
              </b-dropdown-item>
              <b-dropdown-item href="#" class="d-flex align-items-center">
                <feather
                  type="message-circle"
                  class="feather-sm mr-2 mb-n1"
                ></feather>
                Chat
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item href="#" class="d-flex align-items-center">
                <feather
                  type="settings"
                  class="feather-sm mr-2 mb-n1"
                ></feather>
                Settings
              </b-dropdown-item>
              <b-dropdown-item href="#" class="d-flex align-items-center">
                <feather type="log-out" class="feather-sm mr-2 mb-n1"></feather>
                Sign out
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import LogoDark from '../logo-dark/LogoDark';
import LogoLight from '../logo-light/LogoLight';
// import Jwt from "@/common/utils/jwt";

// services
// Comentado pois gera erro // import PessoaService from '@/common/services/pessoa/pessoa.service'

export default {
  name: 'Horizontal-header',
  data: () => ({
    showToggle: false,
  }),
  components: {
    LogoDark,
    LogoLight,
  },
  computed: {
    ...mapState(['navbarColor', 'logoColor', 'LayoutType']),
    getCurrentLanguage() {
      const locale = this.$i18n.locale;
      if (locale == 'en') return { lang: 'flag-icon flag-icon-us' };
      else if (locale == 'fr') return { lang: 'flag-icon flag-icon-fr' };
      else if (locale == 'ch') return { lang: 'flag-icon flag-icon-ch' };
      else if (locale == 'gr') return { lang: 'flag-icon flag-icon-gr' };
      return this.locale;
    },
  },

  mounted() {
    // this.buscarFotoUsuario(jwt.bus)
  },
  methods: {
    showMobileSidebar() {
      this.$store.commit('SET_SIDEBAR_DRAWER', true);
    },
    setMiniSidebar() {
      if (this.LayoutType == 'full-sidebar') {
        this.$store.dispatch('setLayoutType', 'mini-sidebar');
      } else if (this.LayoutType == 'mini-sidebar') {
        this.$store.dispatch('setLayoutType', 'full-sidebar');
      }
    },
    changeLanguage(locale) {
      this.$i18n.locale = locale;
    },
    async buscarFotoUsuario() {
      // try {
      //   // const fotoUsuario = await PessoaService.getFoto()
      // } catch (error) {}
    },
  },
};
</script>
