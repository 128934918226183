<template>
  <header class="topbar">
    <b-navbar
      toggleable="lg"
      :type="`${navbarColor == 'white' ? 'light' : 'dark'}`"
      class="p-0"
      :variant="navbarColor"
    >
      <b-navbar-nav
        ><b-nav-item @click="showMobileSidebar" class="d-block d-lg-none"
          ><i class="mdi mdi-menu fs-3"></i></b-nav-item
      ></b-navbar-nav>
      <div :class="`navbar-header d-block d-lg-none`">
        <LogoDark v-if="navbarColor == 'white'" />
        <LogoLight v-else />
      </div>
      <b-navbar-toggle target="nav-collapse" class="mx-2 border-0"
        ><i class="ti-more"></i
      ></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav class="shadow-sm">
        <!-- -----------------------------------------------------------
          Left aligned nav items
        ----------------------------------------------------------- -->
        <b-navbar-nav>
          <!-- -----------------------------------------------------------
            Toggle Sidebar
          ----------------------------------------------------------- -->
          <b-nav-item @click="setMiniSidebar" class="d-none d-lg-block"
            ><i class="mdi mdi-menu fs-3"></i
          ></b-nav-item>
        </b-navbar-nav>

        <!-- -----------------------------------------------------------
          Right aligned nav items
        ----------------------------------------------------------- -->
        <b-navbar-nav class="ml-auto">
          <!-- -----------------------------------------------------------
            Language Dropdown
          ----------------------------------------------------------- -->
          <b-nav-item-dropdown right no-caret>
            <template #button-content>
              <i :class="getCurrentLanguage.lang"></i>
            </template>
            <b-dropdown-item @click="alterarIdioma('br')"
              ><i class="mr-2 flag-icon flag-icon-br"></i>
              {{ $t('GERAL.PORTUGUES') }}</b-dropdown-item
            >
            <b-dropdown-item @click="alterarIdioma('en')"
              ><i class="mr-2 flag-icon flag-icon-us"></i>
              {{ $t('GERAL.INGLES') }}</b-dropdown-item
            >
          </b-nav-item-dropdown>

          <!-- -----------------------------------------------------------
            Profile Dropdown
          ----------------------------------------------------------- -->
          <b-nav-item-dropdown right no-caret class="mailbox">
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <img
                :src="foto"
                alt="user"
                class="rounded-circle"
                width="31"
              />
            </template>
            <b-dropdown-text class="bg-primary text-white">
              <div class="d-flex align-items-center py-2">
                <b-img :src="foto" width="50" rounded="circle" />
                <div class="ml-3">
                  <h4 class="mb-0">{{ nomeUsuario.replace('null', '') }}</h4>
                  <span class="font-weight-light">{{ email }}</span>
                </div>
              </div>
            </b-dropdown-text>

            <b-dropdown-item
              href="#"
              class="d-flex align-items-center"
              id="show-modal"
              @click="abrirModal"
            >
              <feather type="lock" class="feather-sm mr-2 mb-n1"></feather>
              {{ $t('GERAL.ALTERAR_SENHA') }}
            </b-dropdown-item>
            <b-dropdown-item
              href="#"
              class="d-flex align-items-center"
              @click="logout"
            >
              <feather type="log-out" class="feather-sm mr-2 mb-n1"></feather>
              {{ $t('GERAL.SAIR') }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <modal-altera-senha
      :mostrarModal="mostrarModal"
      @fecharModal="fecharModal"
    />
  </header>
</template>

<script>
import { mapState } from 'vuex';
import LogoDark from '../logo-dark/LogoDark';
import LogoLight from '../logo-light/LogoLight';
import { LOGOUT } from '@/store/autenticacao.module';
import { DEFINIR_IDIOMA } from '@/store/idioma.module';
import JwtService from '@/common/utils/jwt';
import { START_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import ModalAlteraSenha from '@/layouts/components/ModalAlteraSenha';
import fotoUser from '@/assets/images/user.png';

export default {
  name: 'vertical-header',
  data: () => ({
    showToggle: false,
    mostrarModal: false,
  }),
  components: {
    LogoDark,
    LogoLight,
    ModalAlteraSenha,
  },
  computed: {
    ...mapState(['navbarColor', 'logoColor', 'LayoutType']),
    getCurrentLanguage() {
      const locale = this.$i18n.locale;
      if (locale == 'en') return { lang: 'flag-icon flag-icon-us' };
      else if (locale == 'br') return { lang: 'flag-icon flag-icon-br' };
      return this.locale;
    },
    nomeUsuario() {
      return `${JwtService.buscarNome()} ${JwtService.buscarSobrenome()}`;
    },
    email() {
      return JwtService.buscarEmail();
    },
    foto() {
      const foto = JwtService.buscarFoto();
      return foto || fotoUser;
    },
  },
  methods: {
    showMobileSidebar() {
      this.$store.commit('SET_SIDEBAR_DRAWER', true);
    },
    setMiniSidebar() {
      if (this.LayoutType == 'full-sidebar') {
        this.$store.dispatch('setLayoutType', 'mini-sidebar');
      } else if (this.LayoutType == 'mini-sidebar') {
        this.$store.dispatch('setLayoutType', 'full-sidebar');
      }
    },
    alterarIdioma(idioma) {
      this.$store.dispatch(START_LOADING);
      this.$store.dispatch(DEFINIR_IDIOMA, idioma).then(() => {
        location.reload();
      });
      this.$i18n.locale = idioma;
    },
    logout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: 'Login' }))
        .catch((err) => {
          mensagem.showErrors(err);
        });
    },
    abrirModal() {
      this.mostrarModal = true;
    },
    fecharModal() {
      this.mostrarModal = false;
    },
  },
};
</script>
