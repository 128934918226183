<template>
  <aside
    class="left-sidebar"
    :class="
      Sidebar_drawer ? `show-sidebar bg-${SidebarColor}` : `bg-${SidebarColor}`
    "
  >
    <div :class="`bg-${logoColor} navbar-header`">
      <LogoDark v-if="logoColor == 'white'" />
      <LogoLight v-else />
      <span
        class="d-block d-lg-none close-sidebar-btn custom-btn-close"
        @click="showMobileSidebar"
      >
        <i class="ti ti-close text-white" :title="$t('MENU.FECHAR')" />
      </span>
    </div>
    <div class="scrlbar-menu">
      <ul class="nav flex-column mb-0">
        <template v-for="(route, i) in routes">
          <!---Menu Title -->
          <li class="nav-item" v-if="route.header" :key="route.header">
            <div class="d-flex nav-small-cap">
              <i :class="route.icon" />
              <span class="hide-text">{{ route.header }}</span>
            </div>
          </li>
          <!---Sub Menu -->
          <li class="nav-item" :key="route.title" v-if="route.children">
            <a class="nav-link d-flex" v-b-toggle="route.id">
              <feather :type="route.icon" />
              <span class="hide-text">{{ $t(route.title) }}</span>
              <i class="ti ti-angle-down dd-arrow ml-auto hide-text" />
            </a>
            <b-collapse
              :id="route.id"
              accordion="sidebar-accordion"
              :visible="
                $route.path ==
                `${route.name + $route.name.replace(/\s+/g, '-').toLowerCase()}`
              "
            >
              <ul class="nav flex-column sub-menu">
                <template>
                  <li
                    class="nav-item"
                    v-for="(subroute, i) in route.children"
                    :key="i"
                  >
                    <router-link class="nav-link d-flex" :to="`${subroute.to}`">
                      <feather :type="subroute.icon" />
                      <span class="hide-text" :title="$t(subroute.title)">{{
                        $t(subroute.title)
                      }}</span>
                    </router-link>
                  </li>
                </template>
              </ul>
            </b-collapse>
          </li>
          <!---Single Menu -->
          <li class="nav-item" :key="i" v-if="!route.children && !route.header">
            <router-link :to="`${route.to}`" class="nav-link d-flex">
              <feather :type="route.icon" />
              <span class="hide-text">{{ $t(route.title) }}</span>
            </router-link>
          </li>
        </template>
      </ul>
    </div>
    <div id="version">
      {{ versao }}
    </div>
  </aside>
</template>

<script>
import LogoDark from '../logo-dark/LogoDark';
import LogoLight from '../logo-light/LogoLight';
import { mapState } from 'vuex';
import ListaMenus from '../ListaMenus';
import store from '@/store/Store';
import helpers from '@/common/utils/helpers';
import config from '@/config/config';

export default {
  name: 'VerticalSidebar',
  components: {
    LogoDark,
    LogoLight,
  },
  data: () => ({
    initialShow: true,
    routes: ListaMenus,
  }),
  computed: {
    ...mapState(['logoColor', 'Sidebar_drawer', 'SidebarColor']),
    versao() {
      return config.ENV == 'development'
        ? `dev ${config.VERSION}`
        : `prod ${config.VERSION}`;
    },
  },
  mounted() {
    this.listarMenus();
  },
  methods: {
    listarMenus() {
      if (store.getters.admin) return;
      this.routes = helpers.montarMenu(ListaMenus, store.getters.acessos);
    },
    showMobileSidebar() {
      this.$store.commit('SET_SIDEBAR_DRAWER', false);
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}
::-webkit-scrollbar {
  width: 6px;
  background: #f4f4f4;
}
::-webkit-scrollbar-thumb {
  background: #dad7d7;
}
.custom-btn-close {
  font-size: 16px;
  right: 10px;
  padding-inline: 4px;
  transition: all 0.2s;
}
#version {
  text-align: center;
  overflow: hidden;
  position: absolute;
  bottom: 5px;
  font-size: 13px;
  left: 0;
  right: 0;
  font-weight: 700;
}
.sub-menu li a {
  padding: 5px 15px !important;
}
</style>
