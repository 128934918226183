<template>
  <b-modal
    :title="$t('GERAL.ALTERAR_SENHA')"
    header-bg-variant="light"
    ref="modal-login"
    size="md"
    hide-footer
    @hidden="fecharModal()"
  >
    <b-card-text>
      <input-text
        ref="senhaAtual"
        v-model="form.senhaAtual"
        noLabel
        type="password"
        :label="$t('GERAL.SENHA')"
        :placeholder="$t('GERAL.SENHA')"
        :minLength="6"
        required
      />

      <input-text
        ref="novaSenha"
        v-model="form.novaSenha"
        noLabel
        type="password"
        :label="$t('GERAL.NOVA_SENHA')"
        :placeholder="$t('GERAL.NOVA_SENHA')"
        :minLength="6"
        required
      />

      <input-text
        ref="novaSenhaConfirmacao"
        v-model="form.novaSenhaConfirmacao"
        noLabel
        type="password"
        :label="$t('GERAL.CONFIRMAR_NOVA_SENHA')"
        :placeholder="$t('GERAL.CONFIRMAR_NOVA_SENHA')"
        :validate="validarSenha"
        :minLength="6"
        required
      />

      <b-button
        variant="primary"
        size="lg"
        block
        class="mt-4"
        @click="alterarSenha"
        >{{ $t('GERAL.ALTERAR_SENHA') }}</b-button
      >
    </b-card-text>
  </b-modal>
</template>
<script>
import InputText from '@/components/inputs/InputText';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import UsuarioService from '@/common/services/usuario/usuario.service';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
export default {
  name: 'ModalAlteraSenha',
  data() {
    return {
      form: {
        senhaAtual: null,
        novaSenha: null,
        novaSenhaConfirmacao: null,
      },
    };
  },
  components: {
    InputText,
  },
  props: {
    mostrarModal: { type: Boolean, default: false },
  },
  watch: {
    mostrarModal(valor) {
      if (valor) this.$refs['modal-login'].show();
      else this.$refs['modal-login'].hide();
    },
  },
  computed: {
    validarSenha() {
      return this.form.nova_senha === this.form.nova_senha_confirmacao;
    },
  },
  methods: {
    fecharModal() {
      this.$emit('fecharModal');
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    alterarSenha() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      UsuarioService.alterarSenha(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('GERAL.SENHA_ALTERADA')
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
