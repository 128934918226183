import { render, staticRenderFns } from "./VerticalSidebar.vue?vue&type=template&id=a43a040e&scoped=true&"
import script from "./VerticalSidebar.vue?vue&type=script&lang=js&"
export * from "./VerticalSidebar.vue?vue&type=script&lang=js&"
import style0 from "./VerticalSidebar.vue?vue&type=style&index=0&id=a43a040e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a43a040e",
  null
  
)

export default component.exports