<template>
  <div :class="`${LayoutType} ${Theme}`">
    <!-- ---------------------------------- -->
    <!--- Vertical Header part -->
    <!-- ---------------------------------- -->
    <VerticalSidebar v-if="!setHorizontalLayout" />
    <aside
      :class="
        setHorizontalLayout == true ? 'horizontal-page-wrapper' : 'page-wrapper'
      "
    >
      <VerticalHeader v-if="!setHorizontalLayout" />
      <HorizontalHeader v-if="setHorizontalLayout" />
      <HorizontalSidebar v-if="setHorizontalLayout" />
      <div
        class="min-height"
        :class="
          setHorizontalLayout == true
            ? 'container-fluid p-4'
            : 'container-fluid p-4'
        "
      >
        <router-view />
      </div>
    </aside>
  </div>
</template>

<script>
import VerticalHeader from './vertical-header/VerticalHeader';
import HorizontalHeader from './horizontal-header/HorizontalHeader';
import HorizontalSidebar from './horizontal-sidebar/HorizontalSidebar';
import VerticalSidebar from './vertical-sidebar/VerticalSidebar';
import { mapState } from 'vuex';
export default {
  name: 'FullLayout',
  components: {
    VerticalHeader,
    VerticalSidebar,
    HorizontalSidebar,
    HorizontalHeader,
  },
  computed: {
    ...mapState(['LayoutType', 'Theme', 'setHorizontalLayout']),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    winWidth: function () {
      setInterval(() => {
        var w = window.innerWidth;

        if (w < 992) {
          this.$store.commit('SET_LAYOUT_TYPE', 'mini-sidebar');
        }
      }, 100);
    },
  },
  mounted() {
    this.winWidth();
  },
};
</script>
