<template>
  <b-navbar-brand :href="homeRota" class="navbar-brand--cej">
    <!-- Logo icon -->
    <b class="logo-icon">
      <img
        src="@/assets/images/logo-icon.png"
        class="img-fluid"
        alt="homepage"
      />
    </b>
    <span class="logo-text">
      <p>Lições para toda a vida!</p>
    </span>
  </b-navbar-brand>
</template>

<script>
export default {
  name: 'LogoDark',
  computed: {
    homeRota() {
      return process.env.BASE_URL + 'dashboard';
    },
  },
};
</script>
<style scoped>
.logo-icon {
  padding: 0 10px;
  width: 60px;
  height: 56px;
}
.navbar-brand--cej {
  height: 100%;
  background-color: #3e4095;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  padding: 5px 10px;
  transition: all 0.2s;
}
.navbar-brand--cej:hover {
  color: white;
  filter: opacity(0.9);
}

.logo-text {
  display: block;
  margin: auto 0;
}

.logo-text p {
  line-height: 1.2;
  margin: auto;
}

i {
  color: white;
  font-size: 19px;
}
</style>
